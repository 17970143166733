import { createGlobalStyle } from 'styled-components';
import { palette, font } from 'styled-theme';
import 'antd/dist/antd.css';

const GlobalStyles = createGlobalStyle`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Roboto';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;
      
      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }


/*-----------------------------------------------*/ 
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

font-family: ${font('primary', 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette('primary', 0)};
    color: #fff;
  }
}

.ant-row:not(.ant-form-item) {
  ${'' /* margin-left: -8px;
  margin-right: -8px; */};
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

body .ant-form-item-label {
  text-align: left;
}
body textarea.ant-input {
  min-height: 100px;
  resize: none;
}

.text-right {
  text-align: right; }
  @media (max-width: 575px) {
    .text-right {
      text-align: center; 
  }
}
.texto-center {
  text-align: center;
}

/*form image*/
.form-item-image {
  height: 150px;
  width: auto;
}

/*ck-editor styles*/
div.ck-editor__editable {
  min-height: 150px;
}

.ck-content p {
  margin-bottom: 15px;
}

.profile_picture {
  width: 60px;
  height: 60px; 
}

.isoTableSearchBox {
  display: flex;
  margin-bottom: 15px;
}
.minus-circle-custom.anticon svg {
  width: 14px;
  height: 14px;
}

.dashboard-stats {
  text-align: center;
  font-family: 'Roboto'; }
  .dashboard-stats h2 {
    font-weight: 600;
    font-size: 34px;
    margin-bottom: 0px; }
  .dashboard-stats h2, .dashboard-stats h3 {
    font-family: 'Roboto';
    font-weight: 500;
    color: #041e42; }
  .dashboard-stats p {
    margin-bottom: 30px; }
    @media (max-width: 575px) {
      .dashboard-stats p {
        margin-bottom: 1em; } }
  .dashboard-stats .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0px; }
  .dashboard-stats .ant-table-thead > tr:first-child > th:first-child {
    width: unset !important;
    min-width: unset !important; }
  .dashboard-stats .ant-table-thead > tr > th span {
    display: block !important;
    text-align: center; }
  .dashboard-stats .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center; }
  .dashboard-stats .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
  .dashboard-stats .ant-table-thead > tr > th {
    color: #fff !important;
    border: none;
    font-size: 16px;
    padding: 5px;
    text-align: center !important;
  }
  .dashboard-stats .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th:nth-child(1),
  .dashboard-stats .ant-table-thead > tr > th:nth-child(1) {
    background-color: #041e42; }
  .dashboard-stats .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th:nth-child(2),
  .dashboard-stats .ant-table-thead > tr > th:nth-child(2) {
    background-color: #1F4F77; }
  .dashboard-stats .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th:nth-child(3),
  .dashboard-stats .ant-table-thead > tr > th:nth-child(3) {
    background-color: #49678C; }
  .dashboard-stats .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th:nth-child(4),
  .dashboard-stats .ant-table-thead > tr > th:nth-child(4) {
    background-color: #6F86A8; }
`;

export default GlobalStyles;
